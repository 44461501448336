<template>
  <div
    v-loading="loading"
    element-loading-spinner="el-loading-spinner"
    element-loading-background="rgba(0, 0, 0, 0.1)"
  >
    <el-container style="height: 100vh; display: flex; flex-direction: column">
      <drawerHeader :step="action"></drawerHeader>
      <el-tabs v-model="activeTab" style="flex-grow: 1" @tab-click="handleTabs">
        <appEventComponent
          :step="action"
          :eventType="eventType"
        ></appEventComponent>
        <connectionsComponent
          :step="action"
          v-if="action.app_id.eventType == 'Action'"
        ></connectionsComponent>
        <el-tab-pane
          label="Trigger"
          name="trigger"
          key="trigger"
          class="trigger_size"
          :disabled="handleTriggerTab(action)"
          v-if="action.app_id.eventType != 'Filter'"
        >
          <renderFields
            v-if="action.form_data"
            :fields="action.form_data"
            :mappingFields="fieldsData"
            :actionInfo="action"
            :automationInfo="automationInfo"
            :isTrigger="false"
          ></renderFields>
        </el-tab-pane>
        <el-tab-pane
          label="Test"
          key="test"
          name="test"
          :disabled="handleTestTab(action)"
          v-if="action.app_id.eventType !== 'Filter'"
        >
          <responseDisplayTable
            v-if="responseData"
            :response="responseData"
            :key="responseData"
          ></responseDisplayTable>
          <el-row :gutter="20">
            <el-col :lg="12" v-if="!action.responseData">
              <el-button
                shadow="always"
                size="large"
                type="primary"
                :loading="submitLoading"
                style="
                  width: 100%;
                  font-size: 17px;
                  height: 48px;
                  border: none;
                  background-color: #f754a2;
                  margin-bottom: 20px; /* Increase the margin-bottom for gap */
                  margin-top: 10px;
                "
                class="custom-button btn-clr"
              >
                <span>Skip Test</span>
              </el-button>
            </el-col>
            <el-col
              :lg="12"
              v-if="action.responseData && action.responseData.length"
            >
              <el-button
                shadow="always"
                size="large"
                type="primary"
                :loading="submitLoading"
                style="
                  width: 100%;
                  font-size: 17px;
                  height: 48px;
                  border: none;
                  background-color: #f754a2;
                  margin-bottom: 10px;
                  margin-top: 10px;
                "
                @click="testAction"
                class="custom-button btn-clr"
              >
                <span>Re-Test Action</span>
              </el-button>
            </el-col>

            <el-col :lg="12" v-else>
              <el-button
                shadow="always"
                size="large"
                type="primary"
                :loading="submitLoading"
                style="
                  width: 100%;
                  font-size: 17px;
                  height: 48px;
                  border: none;
                  background-color: #f754a2;
                  margin-bottom: 10px;
                  margin-top: 10px;
                "
                @click="testAction"
                class="custom-button btn-clr"
              >
                <span>Test Action</span>
              </el-button>
            </el-col>
          </el-row>
        </el-tab-pane>
        <filterSetup
          :action="action"
          :mappingFields="fieldsData"
          v-if="action.app_id.eventType == 'Filter'"
        ></filterSetup>
        <el-button
          shadow="always"
          size="large"
          type="primary"
          :loading="submitLoading"
          style="
            width: 42%;
            font-size: 17px;
            height: 48px;
            border: none;
            background-color: #f754a2;
            margin-bottom: 10px;
            margin-top: 10px;
            position: fixed;
            bottom: 0;
          "
          @click="onSubmit"
          :disabled="handleSubmitButton"
          class="connectButton-propsObject"
        >
          <span>Continue</span>
        </el-button>
      </el-tabs>
    </el-container>
  </div>
</template>
<script>
import store from "@/store/index";
import { mapGetters } from "vuex";
import renderFields from "@/components/connectorApp/renderFields";
import appEventComponent from "./appEventComponent.vue";
import connectionsComponent from "./connectionsComponent.vue";
import drawerHeader from "./drawerHeader.vue";
import filterSetup from "./filterSetup.vue";
import responseDisplayTable from "./responseDisplayTable.vue";
export default {
  components: {
    renderFields,
    appEventComponent,
    connectionsComponent,
    drawerHeader,
    filterSetup,
    responseDisplayTable,
  },
  props: {
    automationInfo: {
      type: String,
      required: true,
    },
    propsObject: {
      type: Object,
      required: true,
    },
    eventType: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      actionUpdateConnectionsData: false,
      textareaOfUpdateDialog: "",
      needConnectButton: false,
      updateConnectionDialog: false,
      deleteConnectionDialog: false,
      loading: false,
      appName: null,
      activeTab: this.$route.params.tab ? this.$route.params.tab : "appEvent",
      action: null,
      actionResponse: null,
      searchText: "",
      showCardContent: false,
      accordion1Expanded: false,
      fieldsData: null,
      formRules: null,
      responseData: null,
    };
  },
  computed: {
    ...mapGetters("globalApis", [
      "getNestedAppFields",
      "getChildAppFields",
      "getActionResponse",
      "getFieldsRequest",
      "getActionResponse",
      "getUpdateActionAppData",
      "getRefreshedAppFields",
      "getReviewFieldsResponse",
    ]),
    handleSubmitButton() {
      return this.handleSumbitbutton(); // Call the method as a computed property.
    },
  },
  created() {
    this.emitter.on("bindDepencyFieldsInParent", (data) => {
      this.listenEventDependencyFields(data);
    });
  },
  async beforeMount() {
    await this.bindData();
    await this.findFields();
  },
  methods: {
    async deleteConnectionEvent() {
      let connectionId = this.action.app_connection_id;
      let response = await this.deleteConnection(connectionId);
      if (response.status) {
        let indexValue = await this.connections.findIndex(
          (obj) => obj._id == connectionId
        );
        this.connections.splice(indexValue, 1);
        this.connectionModel = null;
        this.deleteConnectionDialog = false;
        this.$notify.success({
          title: "Success",
          message: response.message,
        });
      } else {
        this.$notify.error({
          title: "Error",
          message: response.message,
        });
      }
    },
    async updateConnectionNameEvent() {
      let name = this.textareaOfUpdateDialog;
      let connectionId = this.action.app_connection_id;
      let response = await this.updateConnectionName(name, connectionId);
      if (response.status) {
        let matchedId = await this.connections.find(
          (obj) => obj._id == connectionId
        );
        matchedId.name = name;
        this.updateConnectionDialog = false;
        this.$notify.success({
          title: "Success",
          message: response.message,
        });
      } else {
        this.$notify.error({
          title: "Error",
          message: response.message,
        });
      }
    },
    async updateActionapp_id(actionInfo) {
      let params = {
        _id: actionInfo._id,
        app_id: actionInfo.app_id._id,
        automationId: this.automationInfo._id,
        event_id: actionInfo.event_id,
        app_connection_id: actionInfo.app_connection_id,
        form_data: actionInfo.form_data,
        filter_rules: actionInfo.filter_rules,
      };
      this.loading = true;
      await store.dispatch("globalApis/updateActionAppInfoInFlow", params);
      this.loading = false;
    },
    async getActionChildFields(
      parentField,
      childIndex,
      parentIndex,
      matchedAction
    ) {
      let actionEventInfo = await matchedAction.app_id.action_events.find(
        (event) => event._id == matchedAction.event_id
      );
      let params = {
        connection_id: matchedAction.app_connection_id,
        appName: matchedAction.app_id.app_name,
        entityName: actionEventInfo.entity,
        data: parentField,
        childIndex: childIndex,
        parentIndex: parentIndex,
      };
      try {
        this.loading = true;
        await store.dispatch("globalApis/fetchChildFieldsByName", params);
        this.loading = false;
        if (this.getChildAppFields && this.getChildAppFields.data) {
          matchedAction.fieldsObject.labels[parentIndex].children[
            childIndex + 1
          ].value = this.getChildAppFields.data;
        }
      } catch (error) {
        return error;
      }
    },
    async getActionNestedFields(ParentValue, parentIndex, matchedAction) {
      let actionEventInfo = await matchedAction.app_id.action_events.find(
        (event) => event._id == matchedAction.event_id
      );
      let params = {
        connection_id: matchedAction.app_connection_id,
        appName: matchedAction.app_id.app_name,
        entityName: actionEventInfo.entity,
        data: ParentValue,
        index: parentIndex,
      };
      try {
        this.loading = true;
        await store.dispatch("globalApis/fetchNestedAppFieldsByName", params);
        this.loading = false;
        if (this.getNestedAppFields && this.getNestedAppFields.data) {
          matchedAction.fieldsObject.labels[parentIndex] =
            this.getNestedAppFields.data;
        }
      } catch (error) {
        return error;
      }
    },
    async testAction() {
      let selectedEvent = this.action.event_id;
      let matchedAction = await this.action.app_id.action_events.find(
        (event) => event._id == selectedEvent
      );
      let params = {
        automationId: this.automationInfo._id,
        actionId: this.action._id,
        appName: this.action.app_id.app_name_key,
        entityName: matchedAction.entity,
        operation: matchedAction.operation,
      };
      this.loading = true;
      await store.dispatch("globalApis/runAction", params);
      this.loading = false;
      this.responseData = this.getActionResponse.data;
    },
    toggleText(tag) {
      tag.showFullText = !tag.showFullText;
    },
    toggleTextFortag(item) {
      item.showFullText = !item.showFullText;
    },
    showCard(field) {
      field.isMapping = true;
      this.accordion1Expanded = true;
    },
    hideCard(field) {
      field.isMapping = false;
    },
    toggleTag(tag, accordion, field) {
      field.mappedFields.push(tag);
    },
    removeTag(index, field) {
      field.mappedFields.splice(index, 1);
    },
    clearSearch() {
      this.searchText = "";
    },
    async findFields() {
      try {
        if (this.action._id) {
          this.loading = true;
          let params = {
            automationId: this.automationInfo._id,
            actionId: this.action._id,
          };
          await store.dispatch("globalApis/findFields", params);
          this.loading = false;
          this.fieldsData = this.getFieldsRequest.data;
        }
      } catch (err) {
        return err;
      }
    },
    async onSubmit() {
      if (this.activeTab == "appEvent") {
        await this.updateActionapp_id(this.action);
        let eventType = this.action.app_id.eventType;
        if (eventType === "Filter") {
          this.activeTab = "filterSetup";
        } else if (eventType === "formatter") {
          this.activeTab = "trigger";
        } else if(eventType==="Delay"){
          this.activeTab = "trigger";
        }
        else {
          this.activeTab = "account";
        }
      } else if (this.activeTab == "account") {
        await this.updateActionapp_id(this.action);
        this.activeTab = "trigger";
        if (!this.action.form_data) {
          await this.fetchAppFields();
        }
      } else if (this.activeTab == "trigger") {
        await this.updateActionapp_id(this.action);
        this.activeTab = "test";
      } else if (this.activeTab == "filterSetup") {
        await this.updateActionapp_id(this.action);
        //this.activeTab = "appEvent";
        this.$emit('close')
      } else {
        this.activeTab = "appEvent";
        this.$emit('close')
      }
    },
    handleSumbitbutton() {
      switch (this.activeTab) {
        case "appEvent":
          if (this.action.event_id) {
            return false;
          } else {
            return true;
          }
        case "account":
          if (this.action.app_connection_id) {
            return false;
          } else {
            return true;
          }
        case "trigger":
        case "test":
        case "filterSetup":
          return false;
        default:
          return true; // Optional default case, depending on the expected logic.
      }
    },

    async bindActionFields() {
      let matchedAction = await this.action.app_id.action_events.find(
        (event) => event._id == this.action.event_id
      );
      this.action.form_data = matchedAction
        ? matchedAction.domain_fields
        : null;
      return;
    },

    async fetchAppFields() {
      let matchedEvent = await this.action.app_id.action_events.find(
        (event) => event._id == this.action.event_id
      );
      let params = {
        fieldsSet: matchedEvent,
        connectionId: this.action.app_connection_id,
        automationId: this.automationInfo._id,
        appName: this.action.app_id.app_name_key,
      };
      this.loading = true;
      await store.dispatch("globalApis/reviewFields", params);
      this.loading = false;
      this.action.form_data =
        this.getReviewFieldsResponse.data.fieldsSet.domain_fields;
    },
    async showFields() {
      // await this.bindActionFields();
      await this.updateActionapp_id(this.action);
    },
    async bindData() {
      this.action = this.propsObject;
    },
    async refreshAction(ParentValue, parentIndex, matchedAction) {
      let actionEventInfo = await matchedAction.app_id.action_events.find(
        (event) => event._id == matchedAction.event_id
      );
      let params = {
        connection_id: matchedAction.app_connection_id,
        appName: matchedAction.app_id.app_name,
        entityName: actionEventInfo.entity,
        data: ParentValue,
        index: parentIndex,
      };
      this.loading = true;
      await store.dispatch("globalApis/fetchRefreshingFields", params);
      this.loading = false;
      if (this.getRefreshedAppFields.data) {
        matchedAction.fieldsObject.labels[parentIndex] =
          this.getRefreshedAppFields.data;
      } else {
        this.$notify.error({
          title: "Error",
          message: this.getRefreshedAppFields.message,
        });
      }
    },
    async selectExisistingConnection() {
      await this.updateActionapp_id(this.action);
      // await this.fetchAppFields();
      // this.dynamicComponentName = this.action.app_connection_id;
    },
    listenEventDependencyFields(data) {
      this.action.form_data = data;
    },
    async handleTabs(tab) {
      this.activeTab = tab.props.name;
      this.$router.push({
        name: "workflow",
        params: {
          automationFlowId: this.$route.params.automationFlowId,
          actionId: this.$route.params.actionId,
          tab: this.activeTab,
          step: this.$route.params.step,
        },
      });
      if (tab.props.name == "trigger") {
        if (!this.action.form_data) {
          await this.fetchAppFields();
        }
      }
    },
    handleTriggerTab(action) {
      if (action.app_id.isAuthenticating) {
        if (action.app_connection_id) {
          return false;
        } else {
          return true;
        }
      } else {
        return false;
      }
    },
    handleTestTab(action) {
      if (action.app_id.isAuthenticating) {
        if (action.app_connection_id && action.form_data) {
          return false;
        } else {
          return true;
        }
      } else {
        return false;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.connection-container {
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.new-name {
  color: #606266;
  font-size: 16px;
}

.ui-card {
  margin: 20px;
}

.ui-content {
  margin: 20px;
}

.wide-input {
  width: 260px;
}

.info-section {
  color: darkgray;
  font-size: 13px;
  font-weight: 100;
}

.info-text {
  color: darkgray;
  font-size: 13px;
  font-weight: 100;
}

.info-link {
  color: cornflowerblue;
}

.drawer-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #ffffff;
  color: #1b4554;
  position: fixed;
  height: 90px;
  top: 0;
  left: 50;
  right: 0;
  z-index: 999;
}

.drawer {
  top: 0;
  position: fixed;
  display: flex;
  align-items: flex-start;
  background-color: #ffffff;
  padding: 1%;
  height: 80px;
  border: 1px solid #e5e5e5;
  z-index: 1;
  right: 0;
  width: 35%;
}

.drawer-title-p {
  color: #909399;
  font-size: 14px;
}

.linkText {
  color: #909399;
  font-size: 14px;
}

.drawer-title {
  color: #303133;
  font-size: 18px;
}

.drawer-logo {
  border-radius: 0px;
  border: none;
  display: flex;
  align-items: center;
}

.select-box .el-input__inner {
  width: 340px;
}

.oauth-connect-button {
  margin-top: 10px;
}

.drawer-card-header {
  color: #303133;
  font-size: 16px;
}

.drawer-footer {
  background-color: #ffffff;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.button-connect-drawer {
  margin-top: 10px;
}

.textStyle {
  font-size: 18px;
}

.tokenField {
  margin-bottom: 10px;
}

.el-input--large .el-input__wrapper {
  background-color: red;
}

.select-connections {
  color: #606266;
  font-size: 16px;
  margin-bottom: 10px;
}

.flex-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.trigger_size {
  overflow-y: auto;
  /* Add scrollbar for vertical overflow */
  max-height: 60vh;

  /* Set maximum height for scrollbar */
}

.tags-container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  // margin-top: 8px;
  overflow-y: auto;

  width: 100%;
}

.tags-container .el-tag {
  margin-right: 8px;
  margin-bottom: 8px;
}

.artboard {
  background: rgba(255, 255, 255, 0.8);
  display: flex;
  align-items: center;
  width: 100%;
  flex-wrap: wrap !important;
}

.tag-textarea {
  width: calc(100% - 2px);
  // border: 1px solid #ccc;
  border-radius: 4px;
  padding: 8px;
  resize: vertical;
}

.button-row {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  overflow-y: auto;
  max-height: 160px;
  /* Adjust this value based on the height of your buttons */
}

.button-with-icon {
  display: flex;
  align-items: center;
  margin-top: 5px;
  background-color: rgb(255, 255, 255);
  border: 1px solid #684fa9;
  box-shadow: rgb(149, 146, 142) 0px 0px 0px 1px;
  border-radius: 5px;
  cursor: pointer;
  padding: 15px;
  white-space: normal;
}

.button-with-icon:hover {
  display: flex;
  align-items: center;

  margin-top: 5px;
  border: 1px solid #684fa9;
  background-color: rgb(232, 231, 228);
  border-radius: 3px;
  box-shadow: rgb(149, 146, 142) 0px 0px 0px 1px;
}

.button-icon {
  height: 18px !important;
  width: 20px !important;
  margin-right: 5px !important;
}

.clrs {
  font-family: var(
    --zds-typography-base,
    "Inter",
    Helvetica,
    arial,
    sans-serif
  );
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  color: rgb(45, 46, 46);
  overflow-wrap: break-word;
}

.clrs:hover {
  font-family: var(
    --zds-typography-base,
    "Inter",
    Helvetica,
    arial,
    sans-serif
  );
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  color: rgb(45, 46, 46);
  overflow-wrap: break-word;
}

.clrl {
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #909399;
}

.expanded {
  white-space: normal;
}

.clr {
  color: #684fa9;
  text-decoration: underline;
  /* Add underline style */
  font-weight: bold;
  /* Make the font style bold */
  margin-left: 3px;
}
</style>
